import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import '../Login.css'
import portalLogo from '../../../assets/farmkar_assets/Png/logo-black.png'
import { handlePostRequest } from "../../../services/PostTemplate";

const ResetPassword = () => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const phoneNo = location.state?.phoneNo;

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(prevState => !prevState);
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .oneOf([Yup.ref('confirmPassword'), null], 'Passwords must match')
            .required('Please confirm your password'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Please confirm your password')
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            password: "",
            confirmPassword: "",
        },

        onSubmit: async (data) => {

            setSaveBtnLoading(true);
            const obj = {
                password: formik.values.password,
                confirmPassword: formik.values.confirmPassword,
                phone: phoneNo,
            }
            const response = await dispatch(handlePostRequest(obj, "/api/v1/users/resetPassword", false, true));
            if (response?.status === 200) {
                history.push("/");
            }
            else {
                setSaveBtnLoading(false)
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (

        <>
            <div className="login-page-container splash-screen">
                <div className="left-section ml-6">
                    <div className="login2-box">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="login-headings">
                                <h2 style={{ fontWeight: "700" }}> Reset Password </h2>
                                <p className="mb-4" style={{ color: "gray", fontWeight: "600", fontSize: "18px" }} > Your previous password has been resented. Please set a new password for your account! </p>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="password" className="font-medium text-xl">
                                    Password
                                </label>
                                <div className="mb-4">
                                    <div className="flex flex-row">
                                        <InputText
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Enter your password"
                                            id="password"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            className="w-full login-input mt-3"
                                        />
                                        <i
                                            style={{ color: 'gray', marginLeft: "-30px", marginTop: "30px" }}
                                            className={!showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                            onClick={togglePasswordVisibility}
                                        />
                                    </div>
                                    {getFormErrorMessage("password")}
                                </div>

                                <label htmlFor="password" className="font-medium text-xl ">
                                    Confirm Password
                                </label>
                                <div className="flex flex-row">
                                    <InputText
                                        type={showPassword2 ? 'text' : 'password'}
                                        placeholder="Enter your Confirm password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        className="w-full login-input mt-3"
                                    />
                                    <i
                                        style={{ color: 'gray', marginLeft: "-30px", marginTop: "30px" }}
                                        className={!showPassword2 ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                        onClick={togglePasswordVisibility2}
                                    />
                                </div>
                                {getFormErrorMessage("confirmPassword")}

                                <div className='col-12 d-flex flex-row text-center mt-5'>
                                    <Button className="Login-Button w-full" label="Reset Password" loading={saveBtnLoading} type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
                <div className="right-section">
                    <img src={portalLogo} className="logo-portal mr-6"
                    />
                </div>
            </div>
        </>

    );
};

export default ResetPassword;
