import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import { baseURL } from "../../Config";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { Dropdown } from "primereact/dropdown";

const AddEditCustomer = ({ onHide, getCustomersList, addEditUser, userRowData, viewDisable }) => {
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        englishName: Yup.mixed().required("This field is required."),
        urduName: Yup.mixed().required("This field is required."),
        email: Yup.mixed().required("This field is required."),
        phone: Yup.mixed().required("This field is required."),
        cnic: Yup.mixed().required("This field is required."),
        // pin: Yup.mixed().required("This field is required."),
        status: addEditUser ? Yup.mixed().required("This field is required.") : null,
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            englishName: "",
            urduName: "",
            email: "",
            phone: "",
            cnic: "",
            // pin: "",
            status: "",
        },

        onSubmit: async (data) => {
            if (addEditUser === true) {
                let obj = {
                    id: userRowData?.id,
                    Name: formik.values.englishName,
                    urduName: formik.values.urduName,
                    email: formik.values.email,
                    profileImage: selectedFiles[0]?.base64,
                    phone: formik.values.phone,
                    cnic: formik.values.cnic,
                    // pin: formik.values.pin,
                    isActive: formik.values.status,
                };

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/farmer/update`, false, true));
                if (response?.status === 200) {
                    getCustomersList();
                    setSaveBtnLoading(false);
                    onHide();
                } else if (response?.status === 403) {
                    window.localStorage.clear();
                    history.push("/");
                    toast.info("Please Login again");
                }
                getCustomersList();
                setSaveBtnLoading(false);
                onHide();
            } else {
                setSaveBtnLoading(true);

                let obj = {
                    name: formik.values.englishName,
                    urduName: formik.values.urduName,
                    profileImage: selectedFiles[0]?.base64,
                    email: formik.values.email,
                    phone: formik.values.phone,
                    cnic: formik.values.cnic,
                    // pin: formik.values.pin,
                    // referral: '',
                    // fcmToken: '',
                };

                const response = await dispatch(handlePostRequest(obj, "/api/v1/farmer/signUp", true, true));
                console.log("response", response);
                if (response) {
                    getCustomersList();
                    setSaveBtnLoading(false);
                    onHide();
                }
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get User By Id
    const getCustomerById = async () => {
        console.log("userRowData", userRowData);
        setIsActive(true);

        formik.setFieldValue("englishName", userRowData?.name);
        formik.setFieldValue("urduName", userRowData?.urduName);
        formik.setFieldValue("phone", userRowData?.phone);
        formik.setFieldValue("email", userRowData?.email);
        formik.setFieldValue("pin", userRowData?.pin);
        formik.setFieldValue("cnic", userRowData?.cnic);
        formik.setFieldValue("status", userRowData?.isActive);

        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getCustomerById();
        }
    }, [userRowData, addEditUser]);

    //File Upload Function
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);

        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFiles((prevFiles) => [{ file_extension: file.type, base64: base64String, name: file.name, objectURL: file.objectURL || null }]);
            };
            reader.readAsDataURL(file);

            return file;
        });
    };

    const removeFile = () => {
        setSelectedFiles([]);
    };

    const removeFile2 = () => {
        setSelectedFiles([]);
        userRowData.profileImage = "";
    };

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? "none" : "auto",
        opacity: viewDisable ? 0.7 : 1,
    };

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color: "gray",
                        background: "#798403",
                        width: "107.9%",
                        height: "125%",
                        top: "-27px",
                        left: "-35px",
                    }),
                }}
            >
                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> English Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder="Enter english name" className="p-inputtext-sm mt-2" id="englishName" Name="englishName" value={formik.values.englishName} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("englishName")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Urdu Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    placeholder="Enter urdu name"
                                    className="p-inputtext-sm mt-2"
                                    id="urduName"
                                    name="urduName"
                                    value={formik.values.urduName}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[\u0600-\u06FF\s]*$/.test(value)) {
                                            formik.setFieldValue("urduName", value);
                                        }
                                    }}
                                    type="text"
                                />
                                {getFormErrorMessage("urduName")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Email </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder="Enter Email" className="p-inputtext-sm mt-2" id="email" name="email" value={formik.values.email} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("email")}
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Contact No. </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputMask mask="03999999999" placeholder="03XXXXXXXXX" className="p-inputtext-sm mt-2" id="phone" name="phone" value={formik.values.phone || ""} onChange={formik.handleChange} />
                                {getFormErrorMessage("phone")}
                            </div>
                        </div>
                        <div className="col-12 fields-display justify-content-evenly">
                            {/* <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Pin </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter pin' className="p-inputtext-sm mt-2" id="pin" name="pin" value={formik.values.pin} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("pin")}
                            </div> */}

                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Cnic </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputMask mask="9999999999999" placeholder="XXXXXXXXXXXXX" maxLength={13} className="p-inputtext-sm mt-2" id="cnic" name="cnic" value={formik.values.cnic} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("cnic")}
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Upload Image </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                {(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && (userRowData?.profileImage !== "" || selectedFiles?.length !== 0)) ? null : (
                                    <FileUpload
                                        disabled={(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && userRowData?.profileImage !== "") || selectedFiles?.length !== 0 ? true : false}
                                        auto
                                        mode="basic"
                                        chooseLabel="Choose File"
                                        className="mt-2"
                                        onSelect={handleFileUpload}
                                        accept="image/*"
                                    />
                                )}

                                {!addEditUser ? (
                                    <div className="flex flex-row justify-content-between mt-2">
                                        {selectedFiles?.length ? (
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                                                    {" "}
                                                    <img className="img-styling" src={selectedFiles[0]?.base64} />{" "}
                                                </span>
                                                <Button tooltip="Remove" tooltipOptions={{ position: "top" }} type="button" icon="pi pi-times" className="ml-2" style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }} onClick={() => removeFile()} />
                                            </>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="flex flex-row justify-content-between mt-2">
                                        {userRowData?.profileImage ? (
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                                                    <img className="img-styling" src={`${baseURL}/${userRowData?.profileImage}`} alt="Profile Image" />{" "}
                                                </span>
                                                <Button tooltip="Remove" tooltipOptions={{ position: "top" }} type="button" icon="pi pi-times" className="ml-2" style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }} onClick={() => removeFile2()} />
                                            </>
                                        ) : selectedFiles?.length ? (
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                                                    <img className="img-styling" src={selectedFiles[0]?.base64} alt="Profile Image" />{" "}
                                                </span>
                                                <Button tooltip="Remove" tooltipOptions={{ position: "top" }} type="button" icon="pi pi-times" className="ml-2" style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }} onClick={() => removeFile()} />
                                            </>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                {addEditUser ? (
                                    <>
                                        <div className="flex flex-row">
                                            <label> Status </label>
                                            <span className="Label__Required">*</span>
                                        </div>
                                        <Dropdown
                                            placeholder="--Select Status--"
                                            options={[
                                                { name: "Active", value: true },
                                                { name: "Inactive", value: false },
                                            ]}
                                            optionLabel="name"
                                            optionValue="value"
                                            className="p-inputtext-sm mt-2"
                                            id="status"
                                            name="status"
                                            value={formik.values.status}
                                            onChange={formik.handleChange}
                                        />
                                        {getFormErrorMessage("status")}
                                    </>
                                ) : null}
                            </div>
                            <div className="col-12 flex flex-column md:col-5"></div>
                        </div>

                        {!viewDisable ? (
                            <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button
                                    disabled={(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && userRowData?.profileImage !== "") || selectedFiles?.length !== 0 ? false : true}
                                    className="Save-Button ml-1 mb-2"
                                    label={addEditUser ? "Update" : "Add"}
                                    loading={saveBtnLoading}
                                    type="submit"
                                />
                            </div>
                        ) : null}
                    </div>
                </form>
            </LoadingOverlay>
        </>
    );
};

export default AddEditCustomer;
