import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { BrowserRouter, Routes, Route, useLocation, Switch, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import { useSelector } from "react-redux";
import Login from "./pages/login/Login";
import ManageUsers from "./pages/UserManagement/ManageUsers";
import ForgotPassword from "./pages/login/forgetpassword/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword/ResetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import CustomerManagement from "./pages/CustomerManagement/CustomerManagement";
import FarmerLenderManagement from "./pages/FarmerLenderManagement/FarmerLenderManagement";
import Common from "./pages/Common/Common";
import AddEditFarmerLenderManagement from "./pages/FarmerLenderManagement/AddEditFarmerLenderManagement";
import deleteImage from '../src/assets/farmkar_assets/Png/deleteImage.png'
import { confirmDialog } from "primereact/confirmdialog";
import EquipmentManagement from "./pages/EquipmentManagement/EquipmentManagement";
import Otp from "./pages/login/OTP/Otp";

const App = () => {

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const isLoading = useSelector((state) => state?.utilitySlice?.isLoading);

    const role = localStorage.getItem("roleName")
    const history = useHistory()

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {

        menuClick = true;
        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const CustomConfirmationDialog = ({ message, acceptAction }) => {

        return (
            <div style={{ overflowX: 'auto' }}>
                <img src={deleteImage} alt="Delete" />
                <p className="mt-3 mb-2 ml-5" style={{ color: "black", fontWeight: "600", fontSize: '16px' }}>{message}</p>
            </div>
        );
    };
    
    const handleLogOut = (rowData) => {

        const acceptAction = () => handleLogoutAction(rowData);
        confirmDialog({
            message: <CustomConfirmationDialog message="Are you sure you want to Logout?" acceptAction={acceptAction} />,
            header: '',
            accept: acceptAction,
            reject: null
        });
    };

    const superAdminMenu = [
        {
            items: [
                {
                    label: "Dashboard",
                    icon: 'custom-dashboard-icon',
                    to: "/dashboard",
                },
                {
                    label: "User Management",
                    icon: "custom-user-icon",
                    to: "/users",
                },
                {
                    label: "Customer Management",
                    icon: "custom-customer-icon",
                    to: "/customers",
                },
                {
                    label: "Farmer/ Lender Management",
                    icon: "custom-farmer-icon",
                    to: "/farmers",
                },
                {
                    label: "Equipment Management",
                    icon: "custom-equipment-icon",
                    to: "/equipments",
                },
                {
                    label: "Equipment/ Fuel Types",
                    icon: "custom-user-icon",
                    to: "/common",
                },
                {
                    label: "Logout",
                    icon: "custom-logout-icon",
                    command: handleLogOut
                },
            ],
        },
    ];

    const handleLogoutAction = () => {
        window.localStorage.clear();
        history.push("/");
    }

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    LoadingOverlay.propTypes = undefined;

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {/* <LoadingOverlay active={isLoading} spinner text="Loading ..."> */}
            <ToastContainer />
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            {(localStorage.getItem("login") === null || localStorage.getItem("login") === undefined) ?

                (
                    <>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/forgotpassword" component={ForgotPassword} />
                        <Route exact path="/resetpassword" component={ResetPassword} />
                        <Route exact path="/otp" component={Otp} />
                    </>
                ) : (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={superAdminMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>
                        <div className="layout-main-container innr-Body">
                            <div className="layout-main">

                                <Switch>
                                    {/* <Route path="/login" component={Login} /> */}
                                    <Route path="/dashboard" component={Dashboard} />
                                    <Route path="/users" component={ManageUsers} />
                                    <Route path="/customers" component={CustomerManagement} />
                                    <Route path="/farmers" component={FarmerLenderManagement} />
                                    <Route path="/equipments" component={EquipmentManagement} />
                                    <Route path="/editViewFarmers" component={AddEditFarmerLenderManagement}/>
                                    <Route path="/common" component={Common} />
                                </Switch>

                            </div>
                            {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
                        </div>
                    </>
                )
            }

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
            {/* </LoadingOverlay> */}
        </div>
    );
};


export default App;
