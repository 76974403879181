import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { useHistory } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { baseURL } from '../../Config';
import { handlePatchRequest } from '../../services/PatchTemplate';
import { RadioButton } from 'primereact/radiobutton';

const AddEditEquipmentManagement = ({ onHide, getEquipmentList, addEditUser, userRowData, viewDisable }) => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [allEquipmentTypes, setAllEquipmentTypes] = useState([]);
    const [allFuelTypes, setAllFuelTypes] = useState([]);

    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        englishName: Yup.mixed().required("This field is required."),
        urduName: Yup.mixed().required("This field is required."),
        model: Yup.mixed().required("This field is required."),
        fuelType: Yup.mixed().required("This field is required."),
        perHourChargesType: Yup.mixed().required("This field is required."),
        perDayChargesType: Yup.mixed().required("This field is required."),
        equipmentAddress: Yup.mixed().required("This field is required."),
        latitude: Yup.mixed().required("This field is required."),
        longitude: Yup.mixed().required("This field is required."),
        farmer: Yup.mixed().required("This field is required."),
        equipmentCategory: Yup.mixed().required("This field is required."),
        perDayCharges: Yup.mixed().required("This field is required."),
        perHourCharges: Yup.mixed().required("This field is required."),
        isActive: Yup.mixed().required("This field is required."),

    });

    const formik = useFormik({

        // validationSchema: validationSchema,
        initialValues: {
            englishName: '',
            urduName: '',
            model: '',
            fuelType: '',
            perHourChargesType: '',
            perDayChargesType: '',
            equipmentAddress: '',
            latitude: '',
            longitude: '',
            farmer: '',
            equipmentCategory: '',
            perDayCharges: '',
            perHourCharges: '',
            isActive: '',
        },

        onSubmit: async (data) => {

            if (addEditUser === true) {

                let obj = {
                    equipmentId: userRowData?.id,
                    englishName: formik.values.englishName,
                    urduName: formik.values.urduName,
                    fuelType: formik.values.fuelType,
                    equipmentAddress: formik.values.equipmentAddress,
                    model: formik.values.model,
                    // images: [selectedFiles[0]?.base64],
                    isActive: formik.values.isActive,
                }

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/equipment/update`, false, true));
                if (response?.status === 200) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                }
                else {
                    setSaveBtnLoading(false);
                }
            }

            else {

                setSaveBtnLoading(true);
                let obj = {
                    equipmentCategory: formik.values.equipmentCategory,
                    fuelType: formik.values.fuelType,
                    englishName: formik.values.Name,
                    model: formik.values.model,
                    images: [selectedFiles[0]?.base64],
                }

                const response = await dispatch(handlePostRequest(obj, "/api/v1/equipment/create", true, true));
                if (response) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                }
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get Equipment By Id
    const getEquipmentById = async () => {

        console.log('userRowData', userRowData)
        setIsActive(true)
        formik.setFieldValue("englishName", userRowData?.englishName)
        formik.setFieldValue("urduName", userRowData?.urduName)
        formik.setFieldValue("equipmentAddress", userRowData?.equipmentAddress)
        formik.setFieldValue("equipmentCategory", userRowData?.equipmentCategory?.id)
        formik.setFieldValue("farmer", userRowData?.farmer?.id)
        formik.setFieldValue("fuelType", userRowData?.fuelType?.id)
        formik.setFieldValue("isActive", userRowData?.isActive)
        formik.setFieldValue("latitude", userRowData?.latitude)
        formik.setFieldValue("longitude", userRowData?.longitude)
        formik.setFieldValue("model", userRowData?.model)
        formik.setFieldValue("perDayCharges", userRowData?.perDayCharges)
        formik.setFieldValue("perHourCharges", userRowData?.perHourCharges)
        setIsActive(false)
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getEquipmentById();
        }
    }, [userRowData, addEditUser]);


    //File Upload Function
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);

        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFiles((prevFiles) => [

                    { file_extension: file.type, base64: base64String, name: file.name, objectURL: file.objectURL || null }
                ]);
            };
            reader.readAsDataURL(file);

            return file;
        });
    };

    const removeFile = () => {
        setSelectedFiles([])
    }

    const removeFile2 = () => {
        setSelectedFiles([])
        userRowData.images = [];
    }

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? 'none' : 'auto',
        opacity: viewDisable ? 0.7 : 1,
    };

    //Get All Equipment
    const getAllEquipmentTypes = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/equipmetCategory/getAll", true));
        if (response) {
            setAllEquipmentTypes(response?.data);
        }
        setIsActive(false);
    };


    //Get All Fuel List
    const getAllFuelTypes = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/fuelType/getAll", true));
        if (response) {
            setAllFuelTypes(response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getAllEquipmentTypes()
        getAllFuelTypes()
    }, [])

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color: "gray",
                        background: 'none',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Equipmemt (English Name) </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter english name' className="p-inputtext-sm mt-2" id="englishName" name="englishName" value={formik.values.englishName} onChange={formik.handleChange} type='text' />
                                {getFormErrorMessage("englishName")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Equipmemt (Urdu Name) </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={20} placeholder='Enter urdu name' className="p-inputtext-sm mt-2" id="urduName" name="urduName" value={formik.values.urduName} type='text' onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[\u0600-\u06FF\s]*$/.test(value)) {
                                        formik.setFieldValue("urduName", value);
                                    }
                                }} />
                                {getFormErrorMessage("urduName")}
                            </div>
                        </div>

                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Model </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={20} placeholder='Enter model' className="p-inputtext-sm mt-2" id="model" name="model" value={formik.values.model} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("model")}
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Fuel Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder='--Select--' options={allFuelTypes} optionLabel='Name' optionValue='id' className="p-inputtext-sm mt-2" id="fuelType" name="fuelType" value={formik.values.fuelType} onChange={formik.handleChange} />
                                {getFormErrorMessage("fuelType")}
                            </div>
                        </div>

                        {/* <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Per Hour Charges Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter Per Hour Charges Type' className="p-inputtext-sm mt-2" id="perHourChargesType" name="perHourChargesType" value={formik.values.perHourChargesType} onChange={formik.handleChange} />
                                {getFormErrorMessage("perHourChargesType")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Per Day Charges Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter Per Day Charges Type' className="p-inputtext-sm mt-2" id="perDayChargesType" name="perDayChargesType" value={formik.values.perDayChargesType} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("perDayChargesType")}
                            </div>
                        </div> */}

                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Equipment Address </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter Equipment Address' className="p-inputtext-sm mt-2" id="equipmentAddress" name="equipmentAddress" value={formik.values.equipmentAddress} onChange={formik.handleChange} />
                                {getFormErrorMessage("equipmentAddress")}
                            </div>
                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Status </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder='--Select Status--' options={[{ name: 'Active', value: true }, { name: 'Inactive', value: false }]} optionLabel='name' optionValue='value' className="p-inputtext-sm mt-2" id="isActive" name="isActive" value={formik.values.isActive} onChange={formik.handleChange} />
                                {getFormErrorMessage("isActive")}
                            </div>

                        </div>

                        {/* <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> longitude </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={20} placeholder='Enter Longitude' className="p-inputtext-sm mt-2" id="longitude" name="longitude" value={formik.values.longitude} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("longitude")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Farmer </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder='--Select--' options={allEquipmentTypes} optionLabel='categoryName' optionValue='id' className="p-inputtext-sm mt-2" id="farmer" name="farmer" value={formik.values.farmer} onChange={formik.handleChange} />
                                {getFormErrorMessage("farmer")}
                            </div>
                        </div> */}

                        {/* <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Equipment Category </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder='--Select--' options={allEquipmentTypes} optionLabel='categoryName' optionValue='id' className="p-inputtext-sm mt-2" id="equipmentCategory" name="equipmentCategory" value={formik.values.equipmentCategory} onChange={formik.handleChange} />
                                {getFormErrorMessage("equipmentCategory")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Per Day Charges </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter Per Day Charges' className="p-inputtext-sm mt-2" id="perDayCharges" name="perDayCharges" value={formik.values.perDayCharges} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("perDayCharges")}
                            </div>
                        </div> */}

                        {/* <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Per Hour Charges </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter Per Hour Charges' className="p-inputtext-sm mt-2" id="perHourCharges" name="perHourCharges" value={formik.values.perHourCharges} onChange={formik.handleChange} />
                                {getFormErrorMessage("perHourCharges")}
                            </div>

                          <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> latitude </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter latitude' className="p-inputtext-sm mt-2" id="latitude" name="latitude" value={formik.values.latitude} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("latitude")}
                            </div>
                        </div> */}
                        {/* 
                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Equipment Image </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                {(selectedFiles?.length !== 0 || userRowData?.images.length !== 0) ? null :
                                    <FileUpload
                                        disabled={(selectedFiles?.length !== 0 || userRowData?.images.length !== 0) ? true : false}
                                        auto
                                        mode="basic"
                                        chooseLabel="Choose File"
                                        className="mt-2"
                                        onSelect={handleFileUpload}
                                        accept="image/*"
                                    />
                                }

                                <div className="flex flex-row justify-content-between mt-2">
                                    {userRowData?.images?.length ?
                                        <>
                                            <span style={{ fontSize: "14px", fontStyle: "italic" }}><img className='img-styling' src={`${baseURL}/${userRowData?.images[0]?.images}`} alt="Profile Image" /> </span>
                                            <Button
                                                tooltip='Remove'
                                                tooltipOptions={{ position: "top" }}
                                                type='button'
                                                icon="pi pi-times"
                                                className='ml-2'
                                                style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }}
                                                onClick={() => removeFile2()}
                                            />
                                        </>
                                        :
                                        selectedFiles?.length ?
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}><img className='img-styling' src={selectedFiles[0]?.base64} alt="Profile Image" /> </span>
                                                <Button
                                                    tooltip='Remove'
                                                    tooltipOptions={{ position: "top" }}
                                                    type='button'
                                                    icon="pi pi-times"
                                                    className='ml-2'
                                                    style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }}
                                                    onClick={() => removeFile()}
                                                />
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className='custom-margin flex flex-row'>
                                    <label> Status </label>
                                    <span className="Label__Required">*</span>
                                </div>

                                <div className='flex flex-row mt-3'>
                                    <RadioButton in putId="active" name="status" value={true} onChange={formik.handleChange} checked={formik.values.status === true} />
                                    <label htmlFor="active" className="ml-2">Active</label>
                                    <RadioButton className='ml-8' inputId="inactive" name="status" value={false} onChange={formik.handleChange} checked={formik.values.status === false} />
                                    <label htmlFor="inactive" className="ml-2">InActive</label>
                                </div>
                            </div>
                        </div> */}

                        {!viewDisable ?
                            <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button disabled={selectedFiles?.length !== 0 || userRowData?.images.length !== 0 ? false : true} className="Save-Button ml-1 mb-2" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                            :
                            null}
                    </div>
                </form>

            </LoadingOverlay>
        </>
    )
}

export default AddEditEquipmentManagement