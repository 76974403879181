import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { Divider } from "primereact/divider";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { TabView, TabPanel } from 'primereact/tabview';
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import deleteIcon from '../../assets/farmkar_assets/Png/delete.png'
import editIcon from '../../assets/farmkar_assets/Png/edit.png'
import viewIcon from '../../assets/farmkar_assets/Png/view.png'
import EquipmentType from "./EquipmentType/EquipmentType";
import FuelType from "./FuelType/FuelType";

const Common = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState(false)
    const [userRowData, setUserRowData] = useState();
    const [addEditUser, setAddEditUser] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [viewDisable, setViewDisable] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        return (

            <div className="">
                <Button className='view-icon' onClick={() => handleViewUser(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button>
                <Button className='edit-icon ml-2' onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className='del-icon' onClick={() => confirm(rowData)}>
                    <img src={deleteIcon} alt="Delete" />
                </Button>
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to Delete this User ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteUser(rowData),
            reject: null
        });
    }

    const handleDeleteUser = async (rowData) => {

        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(`/api/user/delete/${rowData?._id}`, true, true));
        if (response?.status === 200) {
            await getUserList()
        }

        else if (response?.status === 403) {
            window.localStorage.clear();
            history.push("/")
            toast.info("Please Login again")
        }

        setIsActive(false);
    }

    const nameTemplate = (rowData) => {

        const result = `${rowData.firstName} ${rowData?.lastName}`
        return (
            <>
                {result}
            </>
        )
    }

    const handleEditUsers = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setUserRowData(rowData);
    };

    const handleViewUser = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setViewDisable(true)
        setUserRowData(rowData);
    };

    const onHide = (name) => {
        setDisplayDialog(false);
        setAddEditUser(false);
        setViewDisable(false);
    };

    const handleDialog = () => {
        setDisplayDialog(true);
    };

    //Get All Users
    const getUserList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/users/", true));
        if (response) {
            setAllUsers(response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getUserList()
    }, [])

    const Header = () => {
        return (
            <>
                <h3 className="pr-2 pl-2 text-center" style={{ fontWeight: "600" }}>{addEditUser && !viewDisable ? "Edit User" : viewDisable && addEditUser ? "View Details" : "Add User"}</h3>
                <div className="mt-2">
                    <hr />
                </div>
            </>
        )
    }

    return (
        <>

            {/* <Divider align="left">
                <div className="inline-flex align-items-center">
                    <h2 style={{ fontWeight: "700", letterSpacing: "-1px", marginLeft: "-10px" }}>User Management </h2>
                </div>
            </Divider> */}

            <div className="card dark-bg">

                <TabView>
                    <TabPanel header="Equipment Type">
                      <EquipmentType/>
                    </TabPanel>
                    <TabPanel header="Fuel Type">
                      <FuelType/>
                      </TabPanel>
                </TabView>
            </div>
        </>
    );
};

export default Common