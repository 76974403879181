import React, { useState, useEffect, useContext } from "react";
import deleteImage from "../assets/farmkar_assets/SVG/delete.svg";
const CustomDeleteDialog = ({ message, acceptAction }) => {
    return (
        <div style={{ overflowX: "auto" }}>
            <img src={deleteImage} alt="Delete" />
            <p className="mt-3 mb-2" style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>
                {message}
            </p>
        </div>
    );
};
export default CustomDeleteDialog;
