import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../../services/PostTemplate";
import { Dialog } from "primereact/dialog";
import { handlePutRequest } from "../../../services/PutTemplate";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { handlePatchRequest } from "../../../services/PatchTemplate";
import { RadioButton } from "primereact/radiobutton";

const AddEditFuelType = ({ onHide, getFuelList, addEditUser, userRowData, viewDisable }) => {
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        Name: Yup.mixed().required("This field is required."),
        urduName: Yup.mixed().required("This field is required."),
        description: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            Name: "",
            urduName: "",
            description: "",
            status: "",
        },

        onSubmit: async (data) => {
            if (addEditUser === true) {
                let obj = {
                    Name: formik.values.Name,
                    urduName: formik.values.urduName,
                    description: formik.values.description,
                    isActive: formik.values.status,
                };

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/fuelType/update/${userRowData?.id}`, false, true));
                if (response) {
                    getFuelList();
                    setSaveBtnLoading(false);
                    onHide();
                }
                getFuelList();
                setSaveBtnLoading(false);
                onHide();
            } else {
                setSaveBtnLoading(true);

                let obj = {
                    Name: formik.values.Name,
                    urduName: formik.values.urduName,
                    description: formik.values.description,
                };

                const response = await dispatch(handlePostRequest(obj, "/api/v1/fuelType/create", true, true));
                if (response) {
                    getFuelList();
                    setSaveBtnLoading(false);
                    onHide();
                }
                getFuelList();
                setSaveBtnLoading(false);
                onHide();
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get User By Id
    const getFuelTypeById = async () => {
        setIsActive(true);
        formik.setFieldValue("Name", userRowData?.Name);
        formik.setFieldValue("urduName", userRowData?.urduName);
        formik.setFieldValue("description", userRowData?.description);
        formik.setFieldValue("status", userRowData?.isActive);
        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getFuelTypeById();
        }
    }, [userRowData, addEditUser]);
    

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? "none" : "auto",
        opacity: viewDisable ? 0.7 : 1,
    };

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color: "gray",
                        background: "#798403",
                        width: "107.9%",
                        height: "125%",
                        top: "-27px",
                        left: "-35px",
                    }),
                }}
            >
                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Fuel Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText keyfilter={/^[a-zA-Z\s]*$/} maxLength={20} placeholder="Enter fuel name" className="p-inputtext-sm mt-2" id="Name" name="Name" value={formik.values.Name} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("Name")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Description </label>
                                </div>
                                <InputText keyfilter={/^[a-zA-Z\s]*$/} maxLength={20} placeholder="Enter description" className="p-inputtext-sm mt-2" id="description" name="description" value={formik.values.description} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("description")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Fuel Name (Urdu) </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    maxLength={12}
                                    placeholder="فیول کا نام درج کریں"
                                    className="p-inputtext-sm mt-2"
                                    id="urduName"
                                    name="urduName"
                                    value={formik.values.urduName}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[\u0600-\u06FF\s]*$/.test(value)) {
                                            formik.setFieldValue("urduName", value);
                                        }
                                    }}
                                    type="text"
                                />
                                {getFormErrorMessage("urduName")}
                            </div>
                            <div className="col-12 flex flex-column md:col-5"></div>
                        </div>

                        {addEditUser ? (
                            <div className="col-12 fields-display justify-content-evenly">
                                <div className="col-12 flex flex-column md:col-5">
                                    <div className="custom-margin flex flex-row">
                                        <label> Status </label>
                                        <span className="Label__Required">*</span>
                                    </div>

                                    <div className="flex flex-row mt-3">
                                        <RadioButton in putId="active" name="status" value={true} onChange={formik.handleChange} checked={formik.values.status === true} />
                                        <label htmlFor="active" className="ml-2">
                                            Active
                                        </label>
                                        <RadioButton className="ml-8" inputId="inactive" name="status" value={false} onChange={formik.handleChange} checked={formik.values.status === false} />
                                        <label htmlFor="inactive" className="ml-2">
                                            InActive
                                        </label>
                                    </div>
                                </div>

                                <div className="col-12 flex flex-column md:col-5"></div>
                            </div>
                        ) : null}

                        {!viewDisable ? (
                            <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button className="Save-Button ml-1 mb-2" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                        ) : null}
                    </div>
                </form>
            </LoadingOverlay>
        </>
    );
};

export default AddEditFuelType;
