import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AddEditEquipmentType from "./AddEditEquipmentType";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handleDeleteRequest } from "../../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom";
import deleteIcon from "../../../assets/farmkar_assets/Png/delete.png";
import editIcon from "../../../assets/farmkar_assets/Png/edit.png";
import viewIcon from "../../../assets/farmkar_assets/Png/view.png";
import deleteImage from "../../../assets/farmkar_assets/Png/deleteImage.png";
import { baseURL } from "../../../Config";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";

const EquipmentType = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);
    const [userRowData, setUserRowData] = useState();
    const [addEditUser, setAddEditUser] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [viewDisable, setViewDisable] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="">
                <Button className="view-icon" onClick={() => handleViewUser(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button>
                <Button className="edit-icon ml-2" onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className="del-icon" onClick={() => confirm(rowData)}>
                    <img src={deleteIcon} alt="Delete" />
                </Button>
            </div>
        );
    };

    const CustomConfirmationDialog = ({ message, acceptAction }) => {
        return (
            <div style={{ overflowX: "auto" }}>
                <img src={deleteImage} alt="Delete" />
                <p className="mt-3 mb-2" style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>
                    {message}
                </p>
            </div>
        );
    };

    const confirm = (rowData) => {
        const acceptAction = () => handleDeleteType(rowData);
        confirmDialog({
            message: <CustomConfirmationDialog message="Are you sure you want to delete this Equipment Type?" acceptAction={acceptAction} />,
            header: "",
            accept: acceptAction,
            reject: null,
        });
    };

    const handleDeleteType = async (rowData) => {
        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(`/api/v1/equipmetCategory/delete/${rowData?.id}`, true, true));
        if (response) {
            await getEquipmentList();
        }

        setIsActive(false);
    };

    const statusTemplate = (rowData) => {
        const status = rowData?.isActive;
        return (
            <Tag
                style={{ fontSize: "11px", padding: "3px 6px" }}
                value={status ? "Active" : "Inactive"} // PrimeReact Tag will handle text display
                severity={status ? "success" : "danger"} // Set style based on status
            />
        );
    };

    const handleEditUsers = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setUserRowData(rowData);
    };

    const handleViewUser = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setViewDisable(true);
        setUserRowData(rowData);
    };

    const onHide = (name) => {
        setDisplayDialog(false);
        setAddEditUser(false);
        setViewDisable(false);
    };

    const handleDialog = () => {
        setDisplayDialog(true);
    };

    //Get All Equipment
    const getEquipmentList = async () => {
        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/equipmetCategory/getAll", true));
        if (response) {
            setAllUsers(response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getEquipmentList();
    }, []);

    const Header = () => {
        return (
            <>
                <h3 className="pr-2 pl-2 text-center" style={{ fontWeight: "600" }}>
                    {addEditUser && !viewDisable ? "Edit Equipment Type" : viewDisable && addEditUser ? "View Details" : "Add Equipment Type"}
                </h3>
                <div className="mt-2">
                    <hr />
                </div>
            </>
        );
    };

    const imageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Image src={`${baseURL}/${rowData.categoryImage}`} zoomSrc={`${baseURL}/${rowData?.categoryImage}`} alt="Image" preview />
            </React.Fragment>
        );
    };

    return (
        <>
            <Dialog draggable={false} header={Header} visible={displayDialog} style={{ width: "64vw" }} onHide={onHide}>
                <AddEditEquipmentType onHide={onHide} getEquipmentList={getEquipmentList} addEditUser={addEditUser} userRowData={userRowData} viewDisable={viewDisable} />
            </Dialog>

            {/* <Divider align="left">
                <div className="inline-flex align-items-center">
                    <h2 style={{ fontWeight: "700", letterSpacing: "-1px", marginLeft: "-10px" }}>User Management </h2>
                </div>
            </Divider> */}

            <div className="card dark-bg">
                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "rgb(38 41 51 / 78%)",
                        }),
                    }}
                >
                    <DataTable
                        header={
                            <>
                                <div className="flex justify-content-between custom-alignment" style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #ccc" }}>
                                    <div>
                                        <span className="">
                                            {/* <i className="pi pi-search" /> */}
                                            <InputText placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} className="search-input mb-3" />
                                        </span>
                                    </div>

                                    <div className="">
                                        <Button
                                            label="Add New"
                                            className="Add__New-Button mb-2"
                                            icon="pi pi-plus"
                                            onClick={() => {
                                                handleDialog();
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        responsive={true}
                        filters={filters}
                        globalFilterFields={["categoryName", "categoryDescription", "isActive"]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allUsers}
                        emptyMessage="No records found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column body={imageTemplate} header="Profile Image"></Column>
                        <Column field="categoryName" header="Category Name"></Column>
                        <Column field="categoryDescription" header="Description"></Column>
                        {/* <Column body={(rowData) => rowData?.isActive === true ? 'Active' : 'Inactive'} header="Status"></Column> */}
                        <Column body={statusTemplate} header="Status"></Column>
                        <Column header="Action" body={actionTemplate} />
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default EquipmentType;
