import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { useHistory, useLocation } from "react-router-dom";
import { Rating } from "primereact/rating";
import moment from "moment";
import { Divider } from "primereact/divider";
import { Triangle } from "react-loader-spinner";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tag } from "primereact/tag";

const AddEditFarmerLenderManagement = ({ onHide, getUserList }) => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const location = useLocation();


    const [pendingRecord, setPendingRecord] = useState('');
    const [onGoingRecord, setOnGoingRecord] = useState('');
    const [acceptedRecord, setAcceptedRecord] = useState('');
    const [completedRecord, setCompletedRecord] = useState('');
    const [receivedRecord, setReceivedRecord] = useState('');
    const [rejectRecord, setRejectRecord] = useState('');
    const [returnRecord, setReturnRecord] = useState('');

    const history = useHistory();
    const dispatch = useDispatch();

    const userRowData = location.state.userRowData;
    console.log('userRowData', userRowData)
    const addEditUser = location.state.addEditUser;
    const viewDisable = location.state.viewDisable;

    const validationSchema = Yup.object().shape({

    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
        },

        onSubmit: async (data) => {

        },
    });

    //Get Record By Id
    const getRecordById = async () => {

        setIsActive(true);
        // const response = await dispatch(handleGetRequest(`/api/v1/equipmentReq/details/${userRowData?.id}`, true));
        // const filteredData = userRowData;
        const pending = userRowData?.statusName == 'Pending'
        const ongoing = userRowData?.statusName == 'On Going'
        const accepted = userRowData?.statusName == 'Accepted'
        const completed = userRowData?.statusName == 'Completed'
        const received = userRowData?.statusName == 'Recieved'
        const rejected = userRowData?.statusName == 'Reject'
        const returnn = userRowData?.statusName == 'Return'

        if (pending) {
            setPendingRecord(pending)
        }
        if (ongoing) {
            setOnGoingRecord(ongoing)
        }
        if (accepted) {
            setAcceptedRecord(accepted)
        }
        if (completed) {
            setCompletedRecord(completed)
        }
        if (received) {
            setReceivedRecord(received)
        }
        if (rejected) {
            setRejectRecord(rejected)
        }
        if (returnn) {
            setReturnRecord(returnn)
        }

        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getRecordById();
        }
    }, [userRowData, addEditUser]);

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgb(38 41 51 / 78%)",
                    }),
                }}
            >
                <div>

                    <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                        <h4 className="farmer-heading">Equipmemt Details</h4>
                        <hr />

                        <div className="ml-4 mt-3 mr-4 grid col-12">
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Farmer Name </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentofFarmerName}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Lender Name </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.requesterFarmerName}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Equipmemt Name </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentEnglishName}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Equipmemt Type </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentFueltype}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Model </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentModel}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Total Cost </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentTotalCost}
                                </span>
                            </div>
                        </div>

                        <div className="ml-4 mt-3 mr-4 grid col-12">
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Equipmemt Category </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentCategoryName}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Equipmemt Charges Type </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentChargesName}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Equipmemt Address </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentAddress}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Latitude </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentLatitude}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Longitude </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentLongitude}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Equipmemt Per day charges </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentPerDayCharges || 'N/A'}
                                </span>
                            </div>
                        </div>

                        <div className="ml-4 mt-3 mr-4 grid col-12">
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Equipmemt Per hour Charges </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentPerHourCharges || 'N/A'}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Lender Address </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentRequestAddress}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Request Date </label>
                                </div>
                                <span className="input-field">
                                    {moment(userRowData?.equipmentRequestCreatedDate).format('Do MMM, YYYY')}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Request from date </label>
                                </div>
                                <span className="input-field">
                                    {moment(userRowData?.equipmentRequestFromDate).format('Do MMM, YYYY')}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Request to date </label>
                                </div>
                                <span className="input-field">
                                    {moment(userRowData?.equipmentRequestToDate).format('Do MMM, YYYY')}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Request Total Days </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentRequestTotalDays}
                                </span>
                            </div>
                        </div>

                        <div className="ml-4 mt-3 mr-4 grid col-12">
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Request Total Hours </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentRequestTotalHours || 'N/A'}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Request updated date </label>
                                </div>
                                <span className="input-field">
                                    {moment(userRowData?.equipmentRequestUpdatedDate).format('Do MMM, YYYY')}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Farmer phone no. </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.equipmentofFarmerPhoneNo}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Lender phone no. </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.requesterFarmerPhoneNo}
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Average Rating </label>
                                </div>
                                <span className="input-field">
                                    {userRowData?.avgRating} Star
                                </span>
                            </div>
                            <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                <div className="flex flex-row">
                                    <label className="label-field mb-2"> Current Status </label>
                                </div>
                                <span className="input-field">
                                    <Tag style={{ fontSize: "11px", padding: '3px 6px', background: userRowData?.statusName == "Accepted" ? 'gray' : userRowData?.statusName == "Completed" ? '#f59e0b' : null }} value={userRowData?.statusName} severity={userRowData?.statusName === "Pending" ? 'info' : userRowData?.statusName == "Recieved" ? 'success' : 'danger'}>
                                    </Tag>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="equipment-card">
                        <Accordion>
                            <AccordionTab header="View Status Details">
                                {userRowData?.statuses?.map(item => {
                                    return (
                                        <div className="lg:col-6 md:col-12 sm:col-12 flex flex-column">
                                            <div className="flex flex-row justify-content-between">
                                                <div className="col-6 flex flex-row">
                                                    <Tag style={{ fontSize: "11px", padding: '3px 6px', background: item?.equipmentRequestStatusName == "Accepted" ? 'gray' : item?.equipmentRequestStatusName == "Completed" ? '#f59e0b' : null }} value={item?.equipmentRequestStatusName} severity={item?.equipmentRequestStatusName === "Pending" ? 'info' : item?.equipmentRequestStatusName == "Recieved" ? 'success' : 'danger'}>
                                                    </Tag>
                                                </div>
                                                <div className="col-6 flex flex-row">
                                                    {moment(item?.statusLogCreatedDate).format('Do MMM, YYYY - hh:mm A')}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </AccordionTab>
                        </Accordion>
                    </div>

                    {/* {!viewDisable ? (
                            <div className="col-12 d-flex flex-row text-center mt-6 pb-2">
                                <Button
                                    className="Cancel-Button"
                                    label="Cancel"
                                    type="button"
                                    onClick={() => {
                                        history.push("/farmers");
                                    }}
                                />
                                <Button className="Save-Button ml-4" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                        ) : null} */}

                </div>

            </LoadingOverlay>
        </>
    );
};

export default AddEditFarmerLenderManagement;