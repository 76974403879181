import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./Login.css";
import portalLogo from "../../assets/farmkar_assets/Png/logo-black.png";
import { handlePostRequest } from "../../services/PostTemplate";
import { Password } from "primereact/password";

const Login = () => {
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const validationSchema = Yup.object().shape({
        email: Yup.mixed().required("This field is required."),
        password: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
            password: "",
        },

        onSubmit: async (data) => {
            setSaveBtnLoading(true);
            const obj = {
                email: formik.values.email,
                password: formik.values.password,
            };
            const response = await dispatch(handlePostRequest(obj, "/api/v1/auth/login", true, true));
            console.log("Login res", response);
            if (response?.status === 200) {
                localStorage.setItem("login", true);
                localStorage.setItem("userId", response?.data?.id);
                localStorage.setItem("firstName", response?.data?.firstName);
                localStorage.setItem("lastName", response?.data?.lastName);
                localStorage.setItem("role", response?.data?.role);
                localStorage.setItem("token", response?.accessToken);
                history.push("/dashboard");
            } else {
                setSaveBtnLoading(false);
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const RouteTo = () => {
        history.push("/forgotpassword");
    };

    return (
        <>
            <div className="login-page-container splash-screen">
                <div className="left-section">
                    <div className="login2-box">
                        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                            <div className="login-headings">
                                <h2 style={{ fontWeight: "700" }}> Welcome! </h2>
                                <p className="mb-4" style={{ color: "gray", fontWeight: "600", fontSize: "18px" }}>
                                    {" "}
                                    Please login to access your account!{" "}
                                </p>
                            </div>
                            <div className="mt-2">
                                <div className="flex flex-column">
                                    <div className="flex flex-row">
                                        <label htmlFor="email1" className="text-xl font-medium">
                                            Email
                                        </label>
                                        <span className="Label__Required">*</span>
                                    </div>
                                    <InputText placeholder="Enter your email" id="email" name="email" value={formik.values.email} onChange={formik.handleChange} type="email" className="w-full login-input mt-2 mb-1" />
                                    {getFormErrorMessage("email")}
                                </div>

                                <div className="flex flex-column mt-4">
                                    <div className="flex flex-row">
                                        <label htmlFor="password" className="font-medium text-xl">
                                            Password
                                        </label>
                                        <span className="Label__Required">*</span>
                                    </div>
                                    <div className="flex flex-row">
                                        <InputText type={showPassword ? "text" : "password"} placeholder="Enter your password" id="password" name="password" value={formik.values.password} onChange={formik.handleChange} className="w-full login-input mt-1 mb-1" />
                                        <i style={{ color: "gray", marginLeft: "-30px", marginTop: "22px" }} className={!showPassword ? "pi pi-eye" : "pi pi-eye-slash"} onClick={togglePasswordVisibility} />
                                    </div>
                                    {getFormErrorMessage("password")}
                                </div>
                                <h5 style={{ color: "#db833a", cursor: "pointer" }} className="text-right" onClick={RouteTo}>
                                    {" "}
                                    Forgot Password?
                                </h5>
                                <div className="col-12 d-flex flex-row text-center mt-6">
                                    <Button className="Login-Button w-full" label="Login" loading={saveBtnLoading} type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="right-section">
                    <img src={portalLogo} className="logo-portal mr-6" />
                </div>
            </div>
        </>
    );
};

export default Login;
